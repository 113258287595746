import { reactive } from 'vue'

let apiUrl = 'https://platform.audology.app/api/';
// if(window.location.origin.indexOf("localhost") > -1 || window.location.origin.indexOf("loc.") > -1) {
// 	apiUrl = 'http://audology.test/api/';
// }

export const store = reactive({
	initialObjectsReceived: false,

	apiUrl: apiUrl,
	inIframe: false,

	showCommas: true,

	chartColourGroup: [
		'#3498DB','#27AE60','#D4AC0D','#D35400','#616A6B','#F1948A','#222222','#D16d77','#226622'
	],
	chartColourGroupARNFirst: [
		'#553AA9','#3498DB','#27AE60','#D4AC0D','#D35400','#616A6B','#F1948A'
	],
	chartColourGroupGradient: [
		'#7bb7db', '#7295d9', '#6c74d9',
		'#7f6bda', '#9d6bda', '#bf6ddb',
		'#d16dcd', '#d06caa', '#d16d77',
		'#cf6b87', '#d18c6b', '#d8af71',
		'#85de70'
	],
	netcolors: [
		{key: 'arn_k', col: '#e50076'},
		{key: 'arn', col: '#e50076'},
		{key: 'arn_p', col: '#EDCA62'},
		{key: 'sca_h', col: '#1bcfc9'},
		{key: 'sca', col: '#1bcfc9'},
		{key: 'sca_m', col: '#fc6402'},
		{key: 'abc_loc', col: '#000000'},
		{key: 'abc_j', col: '#f33e2a'},
		{key: 'nine', col: '#092e52'},
		{key: 'nova', col: '#eb1c24'},
		{key: 'novaent', col: '#eb1c24'},
		{key: 'nova_n', col: '#eb1c24'},
		{key: 'nova_s', col: '#005094'},
		{key: 'ace', col: '#0055a5'},
		{key: 'cada', col: '#718fff'},
	],
	defaultColors: ['#003f5c','#42d534','#2f4b7c','#665191','#a05195','#d45087','#f95d6a','#ff7c43','#ffa600','#a62166'],
	femaleMaleColors:  ['#e50076','#0055a5'],

})