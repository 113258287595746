<template>
	<div>
		<div id="appportal" :class="{iniframe : store.inIframe}">
			<div v-if="!store.inIframe" class="header">
				<div class="inner">
					<div class="logo-hold">
						<img src="@/assets/cra-logo-white.png" alt="Commercial Radio & Audio logo" />
					</div>
				</div>
			</div>
			<div>
				<div v-if="!returnedMeta.gfk">
					<div class="inner">
						<div class="inside">
							<div class="loadview" style="text-align: center;">
								<div v-if="wasLoadingError === false">
									<p class="headline" style="font-size: 16px;">Loading</p>
									<div style="display: flex; justify-content: center; width: 200px; height: 50px; margin: 0 auto; position: relative;">
										<WorkingButtonInsert></WorkingButtonInsert>
									</div>
								</div>
								<div v-if="wasLoadingError === true">
									<p class="headline" style="font-size: 16px; margin-bottom: 10px;">Data load error</p>
									<p>Sorry, there was an error loading the data.  Please try again later.</p>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div v-if="returnedMeta.gfk" class="inner mainsection" :class="{iniframe : store.inIframe}">
					<div class="inside" style="padding-bottom: 0;">
						<div class="table-chart-select-holder">
							<div class="togglers">
								<TogglerSwitch v-on:changeval="activeView = $event" leftlabel="Table" leftval="table" rightlabel="Chart" rightval="chart" />
								<TogglerSwitch v-on:changeval="activeSource = $event" leftlabel="GFK" leftval="gfk" rightlabel="Xtra" rightval="xtra" />
							</div>
							<div class="provider-logo">
								<img v-if="activeSource === 'gfk'" src="@/assets/gfk-logo.png" alt="GFK logo" />
								<img v-else-if="activeSource === 'xtra'" src="@/assets/xtra-logo.png" alt="Xtra Insights logo" />
							</div>
						</div>
						<div class="selections-hold">
							<div class="selections-group">
								<div class="selector">
									<p>Market</p>
									<select class="select-styled" v-model="chosenMarket">
										<option v-for="market in markets" :key="market.id" :value="market.id">{{market.name}}</option>
									</select>
								</div>
								<div class="selector">
									<p>Demographic</p>
									<select class="select-styled" v-model="chosenDemo">
										<option v-for="demo in demos" :key="demo.id" :value="demo.id">{{demo.name}}</option>
									</select>
								</div>
								<div class="selector">
									<p>Daypart</p>
									<select class="select-styled" v-model="chosenDaypart">
										<option v-for="dayp in dayparts" :key="dayp.id" :value="dayp.id">{{dayp.name}}</option>
									</select>
								</div>
								<div class="selector">
									<p>Statistic</p>
									<select class="select-styled" v-model="chosenStatistic">
										<option v-for="stc in statistics" :key="stc.id" :value="stc.id">{{stc.name}}</option>
									</select>
								</div>
								<div v-if="activeSource === 'gfk'" class="selector">
									<p>Radio Type</p>
									<select class="select-styled" v-model="chosenRadioType">
										<option v-for="rt in radioTypes" :key="rt.id" :value="rt.id">{{rt.name}}</option>
									</select>
								</div>
								<div class="selector">
									<p>Station Type</p>
									<select class="select-styled" v-model="chosenBand">
										<option v-for="band in bands" :key="band.id" :value="band.id">{{band.name}}</option>
									</select>
								</div>
								<div v-if="activeSource === 'gfk' && activeView === 'table'" class="selector">
									<p>Year</p>
									<select class="select-styled" v-model="chosenYear">
										<option v-for="year in yearChoices" :key="year" :value="year">{{year}}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div v-if="activeView === 'table' && returnedMeta.gfk && !isFetchingData" class="inside table-holder">
						<div class="" style="padding-top: 0;">
							<NoDataNotice v-if="filteredData.length === 0" :source="activeSource"></NoDataNotice>
							<table v-else id="ratings-table" data-cols-width="30">
								<thead>
								<tr class="headrow">
									<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="align-left sortable-td" @click="clearSort" title="Clear all sorting">Station</td>
									<td v-for="surv in surveyListLimited" :key="surv.id" @click="sortColumn(surv.id)" data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="sortable-td">
										{{surv.nicename}}
										<i v-if="sortColName === surv.id && sortColOrder === 'desc'" class="mdi mdi-arrow-down-thin"></i>
										<i v-else-if="sortColName === surv.id && sortColOrder === 'asc'" class="mdi mdi-arrow-up-thin"></i>
									</td>
								</tr>
								</thead>
								<tbody>
								<tr v-for="(r, ind) in rows" :key="ind">
									<td data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" data-f-bold="true" class="align-left">
										{{r.stationName}}
									</td>
									<td v-for="surv in surveyListLimited" :key="surv.id"
										data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10"
										:title="getTooltip(numdisplayNdp(r[surv.id], displayDecimals), r.stationName)"
									>
										{{numdisplayNdp(r[surv.id], displayDecimals)}}
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div v-if="activeView === 'chart' && returnedMeta.gfk && !isFetchingData" class="inside chart-holder">
						<div style="padding: 20px 10px 0;">
							<span v-for="cf in chartFilterOptions" :key="cf.id" @click="chartFilter = cf.id" class="cfbtn">{{cf.name}}</span>
						</div>
						<div class="">
							<ChartHolderDash>
								<template v-slot:default>
									<NoDataNotice v-if="filteredData.length === 0" :source="activeSource"></NoDataNotice>
									<ApexFreqLineTrendMulti v-else :chartdata="chartdata" :chartcolors="chartcolors"></ApexFreqLineTrendMulti>
								</template>
							</ChartHolderDash>
						</div>
					</div>
					<div class="bottom-section" :class="{chartgap : activeView === 'chart'}">
						<div class="inside">
							<div style="display: flex; gap: 20px; align-items: center;">
								<div>
								<span class="primary-button" @click="exportToExcel('ratings-table')">
									Export
								</span>
								</div>
<!--								<a class="cra-pdf-link" href="https://www.commercialradio.com.au/Radio-Surveys" target="_blank">View CRA PDF release page</a>-->
							</div>
							<div class="poweredby">
								<a href="https://audology.app" target="_blank">Powered by <img src="@/assets/audology-logo.png" class="audlogo" /></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import NoDataNotice from "@/components/NoDataNotice.vue";
import ApexFreqLineTrendMulti from "@/components/ApexFreqLineTrendMulti.vue";
import ChartHolderDash from "@/components/ChartHolderDash.vue";
import TogglerSwitch from "@/components/TogglerSwitch.vue";
import WorkingButtonInsert from "@/components/WorkingButtonInsert.vue";
import TableToExcel from "@linways/table-to-excel";
import axios from "axios";

export default {
	name: "MainPage",
	components: {
		WorkingButtonInsert,
		TogglerSwitch,
		ChartHolderDash,
		ApexFreqLineTrendMulti,
		NoDataNotice
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			sources: [
				{id: 'gfk', name: 'GFK'},
				{id: 'xtra', name: 'Xtra'},
			],
			activeSource: 'gfk',
			views: [
				{id: 'table', name: 'Table'},
				{id: 'chart', name: 'Chart'},
			],
			activeView: 'table',

			returnedMeta: {},
			allStations: [],
			returnedData: [],
			surveyList: [],
			stationIdsUsed: [],
			isFetchingData: false,
			delayFetchingData: false,

			chosenMarket: 110, //sydney
			chosenDemo: 48,
			chosenDaypart: 1,
			chosenStatistic: 'shareToAll',
			chosenBand: 'amfm',
			chosenRadioType: 0,

			chosenYear: 'Recent',

			sortColName: null,
			sortColOrder: 'desc',

			isExporting: false,
			screenWidth: window.innerWidth,
			wasLoadingError: false,

			chartFilter: 'all', //all, comm, commfm, commam, abc
		}
	},
	computed: {
		chartFilterOptions() {
			if(this.chosenBand === 'amfm' || this.chosenBand === 'streaming') {
				return [
					{id: 'all', name: 'All'},
					{id: 'comm', name: 'Commercial'},
					{id: 'commfm', name: 'Commercial FM'},
					{id: 'commam', name: 'Commercial AM'},
					{id: 'abc', name: 'ABC'},
				]
			}
			else {
				return [
					{id: 'all', name: 'All'},
					{id: 'comm', name: 'Commercial'},
					{id: 'abc', name: 'ABC'},
				]
			}
		},
		displayDecimals() {
			if(this.chosenStatistic === 'cume' || this.chosenStatistic === 'avgAud') {
				return 0
			}
			return 1
		},
		markets() {
			if(this.returnedMeta[this.activeSource].markets) {
				return this.returnedMeta[this.activeSource].markets
			}
			return []
		},
		demos() {
			if(this.returnedMeta[this.activeSource].demos) {
				return this.returnedMeta[this.activeSource].demos
			}
			return []
		},
		dayparts() {
			if(this.returnedMeta[this.activeSource].dayparts) {
				return this.returnedMeta[this.activeSource].dayparts
			}
			return []
		},
		statistics() {
			if(this.returnedMeta[this.activeSource].statistics) {
				if(this.chosenBand === 'dab') {
					return this.returnedMeta[this.activeSource].statistics.filter(item => item.id === 'cume')
				}
				return this.returnedMeta[this.activeSource].statistics
			}
			return []
		},
		radioTypes() {
			if(this.activeSource === 'gfk' && this.chosenBand === 'amfm') {
				return [
					{id: 0, name: 'Total Radio'},
					{id: 1, name: 'AM/FM/DAB Only'},
					{id: 2, name: 'Streaming Only'},
				]
			}
			return [{id: 0, name: 'Total Radio'}] //default
		},
		bands() {
			if(this.activeSource === 'gfk' && this.chosenRadioType === 0) {
				return [
					{id: 'amfm', name: 'AM/FM'},
					{id: 'dab', name: 'DAB'},
				]
			}
			return [{id: 'amfm', name: 'AM/FM'}] //default
		},
		activeMarketOb() {
			let mkt = null
			if(this.activeSource === 'gfk') {
				mkt = this.returnedMeta.gfk.markets.find(item => item.id === this.chosenMarket)
			}
			else if(this.activeSource === 'xtra') {
				mkt = this.returnedMeta.xtra.markets.find(item => item.id === this.chosenMarket)
			}
			return mkt
		},
		stationList() {
			let stobs = []
			if(this.activeSource === 'gfk') {
				for(let stid of this.stationIdsUsed) {
					let st = this.allStations.find(item => item.id === stid)
					if(st) stobs.push(st)
				}
				//sort stobs by gfkSequence
				stobs = stobs.sort((a, b) => {
					if(a.gfkSequence < b.gfkSequence) return -1
					else if(a.gfkSequence > b.gfkSequence) return 1
					else return 0
				})
			}
			else { //xtra
				if(this.activeMarketOb) {
					stobs = this.activeMarketOb.stationOrder.filter(item => item.band !== 'dab')
					let matchedStids = stobs.map(item => item.id)
					for(let stid of this.stationIdsUsed) { //get any that have data that are not in the current public order
						let newstob = this.allStations.find(item => item.id === stid)
						if(newstob && newstob.band !== 'dab' && !matchedStids.includes(newstob.id)) {
							stobs.push(newstob)
						}
					}
				}
			}
			return stobs
		},
		surveyListLimited() {
			if(this.surveyList) {
				if(this.chosenYear === 'Recent') {
					return this.surveyList.slice(0, 9)
				}
				else return this.surveyList.filter(item => item.year === parseInt(this.chosenYear))
			}
			return []
		},
		rows() {
			let rows = []
			let stidsHaveData = []
			let allStids = []
			let survidsLimited = this.surveyListLimited.map(item => item.id)
			for(let st of this.stationList) {
				if(!allStids.includes(st.id)) allStids.push(st.id)
				let myrow = []
				myrow.stationName = st.name
				for(let surv of this.surveyList) {
					let cellval = this.getCellData(st.id, surv.id)
					myrow[surv.id] = cellval
					if(survidsLimited.includes(surv.id) && !stidsHaveData.includes(st.id)) {
						if(cellval !== null && cellval !== undefined) {
							stidsHaveData.push(st.id)
						}
					}
				}
				rows.push(myrow)
			}
			//sorting
			if(this.sortColName) {
				if (this.sortColOrder === 'asc') {
					rows = rows.sort((a, b) => (a[this.sortColName] > b[this.sortColName]) ? 1 : -1)
				}
				else {
					rows = rows.sort((a, b) => (a[this.sortColName] > b[this.sortColName]) ? -1 : 1)
				}
			}
			//check if any rows are empty for what is displayed
			let emptyStids = []
			for(let st of this.stationList) {
				if(!stidsHaveData.includes(st.id)) {
					emptyStids.push(st.id)
				}
			}
			if(emptyStids.length > 0) {
				for(let st of this.stationList) {
					if(emptyStids.includes(st.id)) {
						rows = rows.filter(item => item.stationName !== st.name)
					}
				}
			}
			return rows
		},
		chartStationList() {
			let stns = []
			for(let st of this.stationList) {
				if(this.chartFilter === 'abc' && st.netgroupown !== 'abc') continue;
				if(['comm','commfm','commam'].includes(this.chartFilter) && st.netgroupown === 'abc') continue;
				if(this.chartFilter === 'commfm' && st.band !== 'fm') continue;
				if(this.chartFilter === 'commam' && st.band !== 'am') continue;
				stns.push(st)
			}
			return stns
		},
		chartdata() {
			let rows = []
			for(let st of this.chartStationList) {
				let myrow = []
				myrow = {
					name: st.name,
					data: [],
				}
				for(let surv of this.surveyList) {
					myrow.data.push({x: surv.id, y: this.getCellData(st.id, surv.id)})
				}
				myrow.data.reverse()
				rows.push(myrow)
			}
			return rows
		},
		chartcolors() {
			let defaultColorCount = 0
			let colors = []
			for(let st of this.chartStationList) {
				let arr = this.getChartColorForStation(st, defaultColorCount)
				colors.push(arr[0])
				defaultColorCount = arr[1]
			}
			return colors
		},
		filteredData() {
			if(this.chosenMarket && this.chosenDemo && this.chosenDaypart && this.chosenStatistic && this.chosenBand) {
				let myd = []
				if (this.activeSource === 'gfk') {
					if(this.returnedData.length > 0) {
						myd = this.returnedData.filter(item =>
							item.marketId === this.chosenMarket
							&& item.demoId === this.chosenDemo
							&& item.daypartId === this.chosenDaypart
							&& item.statistic === this.chosenStatistic
							&& item.band === this.chosenBand
							&& item.audioType === this.chosenRadioType
						)
					}
				}
				else if (this.activeSource === 'xtra') {
					if(this.returnedData.length > 0) {
						myd = this.returnedData.filter(item => item.marketId === this.chosenMarket && item.demoId === this.chosenDemo && item.daypartId === this.chosenDaypart && item.statistic === this.chosenStatistic && item.band === this.chosenBand)
					}
				}
				return myd
			}
			return []
		},
		myStatisticOb() {
			let sob = null
			if(this.chosenStatistic) {
				if(this.activeSource === 'gfk') {
					sob = this.returnedMeta.gfk.statistics.find(item => item.id === this.chosenStatistic)
				}
				else if(this.activeSource === 'xtra') {
					sob = this.returnedMeta.xtra.statistics.find(item => item.id === this.chosenStatistic)
				}
			}
			return sob
		},
		myDaypartOb() {
			let sob = null
			if(this.chosenDaypart) {
				if(this.activeSource === 'gfk') {
					sob = this.returnedMeta.gfk.dayparts.find(item => item.id === this.chosenDaypart)
				}
				else if(this.activeSource === 'xtra') {
					sob = this.returnedMeta.xtra.dayparts.find(item => item.id === this.chosenDaypart)
				}
			}
			return sob
		},
		myDemoOb() {
			let sob = null
			if(this.chosenDemo) {
				if(this.activeSource === 'gfk') {
					sob = this.returnedMeta.gfk.demos.find(item => item.id === this.chosenDemo)
				}
				else if(this.activeSource === 'xtra') {
					sob = this.returnedMeta.xtra.demos.find(item => item.id === this.chosenDemo)
				}
			}
			return sob
		},
		yearChoices() {
			let years = [] //['Recent']
			for(let i=2013, j=0; i <= new Date().getFullYear(); i++, j++) {
				years.push(i)
			}
			years.reverse()
			let myYears = ['Recent']
			for(let i=0; i < years.length; i++) {
				myYears.push(years[i])
			}
			return myYears
		},
	},
	methods: {
		clearSort() {
			this.sortColName = null
			this.sortColOrder = 'desc'
		},
		exportToExcel(tblid, filename = 'CRA_Ratings_Export') {
			this.store.showCommas = false
			this.isExporting = true
			let self = this
			setTimeout(function() {
				TableToExcel.convert(document.getElementById(tblid), {
					name: filename+".xlsx",
					sheet: {
						name: 'Sheet 1'
					}
				});
				self.store.showCommas = true
				self.isExporting = false
			}, 500)
		},
		initialFetch() {
			let self = this
			axios.get(
				this.store.apiUrl+"cra-lookup-meta"
			).then(function (response) {
				let resp = response.data
				self.returnedMeta = resp.meta
				self.allStations = resp.stations
				if(!self.returnedMeta.gfk) self.wasLoadingError = true
				else self.fetchDataFromCache()
			}).catch(function (error) {
				self.wasLoadingError = true
				console.log(error);
			});
		},
		fetchDataFromCache() {
			if(this.isFetchingData === false && this.chosenMarket && this.chosenDemo && this.chosenDaypart && this.chosenStatistic && this.chosenBand) {
				this.isFetchingData = true
				let self = this
				let sendob = {
					marketId: this.chosenMarket,
					demoId: this.chosenDemo,
					daypartId: this.chosenDaypart,
					statistic: this.chosenStatistic,
					band: this.chosenBand,
					audioType: this.chosenRadioType,
					supplier: this.activeSource, //gfk or xtra
				}
				this.returnedData = []
				this.surveyList = []
				this.stationIdsUsed = []
				axios.post(this.store.apiUrl+"cra-fetch-data", sendob).then(function (response) {
					self.returnedData = response.data.returnedData
					if(!self.returnedData) self.wasLoadingError = true
					self.surveyList = response.data.surveyObs
					self.stationIdsUsed = response.data.stationIds
					self.delayFetchingData = false
					self.isFetchingData = false
				}).catch(function (error) {
					self.wasLoadingError = true
					console.log(error)
					self.delayFetchingData = false
					self.isFetchingData = false
				});
			}
		},
		getCellData(stid, survid) {
			let survitem = this.filteredData.find(
				item => item.survcode === survid
					&& item.statistic === this.chosenStatistic
					&& item.band === this.chosenBand
					&& item.daypartId === this.chosenDaypart
					&& item.demoId === this.chosenDemo
					&& item.audioType === this.chosenRadioType
			)
			if(survitem) {
				let valitem = survitem.data.find(item => item.stid === stid)
				if(valitem) return valitem.val
			}
			return null
		},
		getTooltip(val, stnname) {
			let out = ''
			if(val && val.length > 0 && stnname && stnname.length > 0) {
				let atText = ' Total'
				if(this.chosenRadioType === 1) atText = ' AM/FM/DAB'
				else if(this.chosenRadioType === 2) atText = ' Streaming'
				if (this.activeSource === 'gfk') out = 'Interpreting RADIO360: '
				out = 'For '
				out += this.myDemoOb.name + ', ' + this.myDaypartOb.name + ", "
				out += stnname
				if (this.chosenStatistic === 'cume') {
					out += ' has ' + val + atText + ' listeners per week'
				}
				else if(this.chosenStatistic === 'shareToAll') {
					out += ' has ' + val + '%' + atText + ' share of listening'
				}
				else if(this.chosenStatistic === 'avgAud') {
					out += ' has ' + val + atText + ' average listeners per quarter hour'
				}
				else if(this.chosenStatistic === 'stationListenedMost') {
					out += ' has ' + val + '% of listeners claiming it as "the station they listen to most"'
				}
				if(this.chosenRadioType > 0) {
					out += '\n\nRADIO360 streaming and AM/FM/DAB split listening was only collected from Survey 3 2023 onwards.'
					out += '\n\nNot all stations provided streaming logs to GFK for inclusion in this survey.'
					if(this.chosenStatistic === 'shareToAll') {
						out += '\n\nPlease note AM/FM/DAB and Streaming Share% results exclude in its calculation Other AM, Other FM, Other Digital & stations appearing as n/a.'
					}
				}
			}
			return out
		},
		sortColumn(colfldid) {
			if(this.sortColName === colfldid) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColName = colfldid; //either no column yet chosen or a different column, sort by that col
		},
		onResize() {
			this.screenWidth = window.innerWidth
		},
	},
	watch: {
		activeSource() {
			this.delayFetchingData = true
			if(this.activeSource === 'xtra') {
				this.chosenStatistic = 'stationListenedMost'
				this.chosenBand = 'amfm'
				this.chosenMarket = 23 //albury
				this.chosenRadioType = 0 //total radio
				this.chosenYear = 'Recent'
			}
			else {
				if(this.chosenStatistic !== 'cume') this.chosenStatistic = 'shareToAll'
				this.chosenMarket = 110 //syd
			}
			if(this.chosenBand === 'dab') {
				if(this.chartFilter === 'commfm' || this.chartFilter === 'commam') {
					this.chartFilter = 'comm'
				}
			}
			this.fetchDataFromCache()
		},
		chosenBand() {
			this.delayFetchingData = true
			if(this.chosenBand === 'dab') {
				this.chosenStatistic = 'cume'
				this.chosenRadioType = 0
				this.chosenDemo = 48
				this.chosenDaypart = 1
			}
			this.fetchDataFromCache()
		},
		chosenMarket() {
			if(this.delayFetchingData === false) this.fetchDataFromCache()
		},
		chosenDemo() {
			if(this.delayFetchingData === false) this.fetchDataFromCache()
		},
		chosenDaypart() {
			if(this.delayFetchingData === false) this.fetchDataFromCache()
		},
		chosenStatistic() {
			if(this.delayFetchingData === false) this.fetchDataFromCache()
		},
		chosenRadioType() {
			if(this.delayFetchingData === false) this.fetchDataFromCache()
		},
	},
	mounted() {
		this.initialFetch()
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},
	created() {
		let queryString = window.location.search
		let urlParams = new URLSearchParams(queryString)
		if(urlParams.has('iframe')) {
			let isIframe = urlParams.get('iframe')
			if(isIframe === '1' || isIframe === 'true' || isIframe === true) {
				this.store.inIframe = true
			}
		}
	},
}
</script>
<style scoped>
#appportal {
	min-height: 100vh;
	background-color: #F9F9F9;
}
#appportal.iniframe {
	background-color: #FFF;
}
.header {
	height: 70px;
	background: transparent linear-gradient(270deg,  #E81F76 0%,  #00AEEF 100%) 0% 0% no-repeat padding-box;
	display: flex;
	align-items: center;
}
.header .logo-hold {
	height: 50px;
	display: flex;
	align-items: center;
}
.header .logo-hold img {
	height: 100%;
}
.inner {
	width: 1600px;
	max-width: 1600px;
	margin: 0 auto;
}
.inner.mainsection.iniframe {
	padding-top: 0;
}
.inner .inside {
	width: 1400px;
	max-width: 1400px;
	padding: 40px 0;
	margin: 0 auto;
}
.inner .inside.table-holder,
.inner .inside.chart-holder {
	padding-top: 0;
}
.inner .inside.chart-holder {
	height: auto;
	padding-bottom: 0;
}
.togglers {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}
.table-chart-select-holder {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}
.selections-hold {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 20px 30px;
	background-color: var(--grey_main);
	border-bottom: 1px solid #CCC;
}
.selections-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.provider-logo {
	height: 60px;
	display: flex;
	align-items: center;
}
.provider-logo img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
.selector {
	margin-right: 20px;
}
.selector p {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 5px;
}
.bottom-section {
	margin-bottom: 50px;
}
.bottom-section.chartgap {
	margin-top: 50px;
}
.cfbtn {
	margin-right: 10px;
	font-size: 10px;
	padding: 5px 10px;
	background: var(--grey_main);
	border-radius: 5px;
	border: 1px solid #CCC;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
}
.cfbtn:hover {
	background-color: var(--grey_main_hover);
}

@media screen and (max-width: 1720px) {
	.inner {
		width: 90%;
		max-width: 90%;
		padding: 0 1%;
	}
	.iniframe .inner {
		width: 98%;
		max-width: 98%;
	}
	.inner .inside {
		width: 100%;
		max-width: 100%;
	}
}

@media screen and (max-width: 1120px) {
	.selections-hold {
		flex-wrap: wrap;
	}
	.selections-group {
		flex-wrap: wrap;
	}
	.selector {
		margin-bottom: 10px;
		width: 30%;
		flex-shrink: 0;
	}
}

@media screen and (max-width: 800px) {
	.selector {
		width: 44%;
	}
}

@media screen and (max-width: 600px) {
	.selector {
		width: 100%;
	}
}
</style>