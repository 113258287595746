<template>
	<div>
		<div id="appportal" :class="{iniframe : store.inIframe}">
			<div v-if="!store.inIframe" class="header">
				<div class="inner">
					<div class="logo-hold">
						<img src="@/assets/cra-logo-white.png" alt="Commercial Radio & Audio logo" />
					</div>
				</div>
			</div>
			<div>
				<div v-if="!returnedData.nmr">
					<div class="inner">
						<div class="inside">
							<div class="loadview" style="text-align: center;">
								<div v-if="wasLoadingError === false">
									<p class="headline" style="font-size: 16px;">Loading</p>
									<div style="display: flex; justify-content: center; width: 200px; height: 50px; margin: 0 auto; position: relative;">
										<WorkingButtonInsert></WorkingButtonInsert>
									</div>
								</div>
								<div v-if="wasLoadingError === true">
									<p class="headline" style="font-size: 16px; margin-bottom: 10px;">Data load error</p>
									<p>Sorry, there was an error loading the data.  Please try again later.</p>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div v-if="returnedData.nmr" class="inner mainsection" :class="{iniframe : store.inIframe}">
					<div class="inside" style="padding-bottom: 0;">
						<div class="selections-hold">
							<div class="selections-group">
								<div class="selector">
									<p>Market</p>
									<select class="select-styled" v-model="chosenMarket">
										<option v-for="market in markets" :key="market.id" :value="market.id">{{market.name}}</option>
									</select>
								</div>
								<div class="selector">
									<p>Daypart</p>
									<select class="select-styled" v-model="chosenDaypart">
										<option v-for="dayp in dayparts" :key="dayp.id" :value="dayp.id">{{dayp.name}}</option>
									</select>
								</div>
								<div class="selector">
									<p>Year</p>
									<select class="select-styled" v-model="chosenYear">
										<option v-for="yr in years" :key="yr" :value="yr">{{yr}}</option>
									</select>
								</div>
							</div>
							<div class="provider-logo">
								<img v-if="chosenYear >= 2013" src="@/assets/gfk-logo.png" alt="GFK logo" />
								<img v-else src="@/assets/nielsen-logo.png" alt="Nielsen logo" />
							</div>
						</div>
					</div>
					<div v-if="returnedData.nmr" class="inside table-holder">
						<div class="" style="padding-top: 0;">
							<table v-if="filteredData.length > 0" id="ratings-table" data-cols-width="30">
								<thead>
								<tr class="headrow dprow">
									<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="align-left sortable-td">{{daypartHours}}</td>
									<td v-for="surv in surveyList" :key="surv.id" data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="sortable-td">

									</td>
								</tr>
								<tr class="headrow">
									<td data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="align-left sortable-td">Station</td>
									<td v-for="surv in surveyList" :key="surv.id" data-f-bold="true" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" class="sortable-td">
										{{surv.nicename}}
									</td>
								</tr>
								</thead>
								<tbody>
								<tr v-for="(r, ind) in rows" :key="ind">
									<td data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10" data-f-bold="true" class="align-left">
										{{r.stationName}}
									</td>
									<td v-for="surv in surveyList" :key="surv.id"
										data-t="n" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10"
										:title="getTooltip(numdisplayNdp(r[surv.id], 1), r.stationName)"
									>
										{{numdisplayNdp(r[surv.id], 1)}}
									</td>
								</tr>
								</tbody>
							</table>
							<div class="notes">
								<p v-for="note in activeNotes" :key="note.id" class="note">
									{{note.note}}
								</p>
							</div>
						</div>
					</div>
					<div class="bottom-section">
						<div class="inside">
<!--							<div style="display: flex; gap: 20px; align-items: center;">-->
<!--								<div>-->
<!--								<span class="primary-button" @click="exportToExcel('ratings-table')">-->
<!--									Export-->
<!--								</span>-->
<!--								</div>-->
<!--								<a class="cra-pdf-link" href="https://www.commercialradio.com.au/Radio-Surveys" target="_blank">View CRA PDF release page</a>-->
<!--							</div>-->
							<div class="poweredby">
								<a href="https://audology.app" target="_blank">Powered by <img src="@/assets/audology-logo.png" class="audlogo" /></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
// import NoDataNotice from "@/components/NoDataNotice.vue";
// import TogglerSwitch from "@/components/TogglerSwitch.vue";
import WorkingButtonInsert from "@/components/WorkingButtonInsert.vue";
import axios from "axios";

export default {
	name: "WaybackMachine",
	components: {
		WorkingButtonInsert,
		// TogglerSwitch,
		// NoDataNotice
	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

			chosenMarket: 110, //sydney
			chosenDaypart: 1,
			chosenYear: 1951,

			wasLoadingError: false,
			returnedData: {},
		}
	},
	computed: {
		years() {
			let years = []
			let startYr = 1951
			if(this.chosenDaypart !== 1) startYr = 1975
			for(let i = startYr; i <= 2025; i++) {
				years.push(i)
			}
			return years
		},
		dayparts() {
			return [
				{id: 1, name: 'Overall'},
				{id: 3, name: 'Breakfast'},
				{id: 4, name: 'Morning'},
				{id: 5, name: 'Afternoon'},
				{id: 6, name: 'Drive'},
				{id: 7, name: 'Evening'},
			]
		},
		daypartHours() {
			if(this.chosenDaypart === 1) {
				if(this.chosenYear <= 1952) return 'Mon-Sun 7am-10pm'
				else if(this.chosenYear <= 1961) return 'Mon-Sun 6am-11pm'
				else if(this.chosenYear <= 1965) return 'Mon-Sun 5.30am-11pm'
				return 'Mon-Sun 5.30am-12mn'
			}
			else if(this.chosenDaypart === 3) {
				return 'Mon-Fri 5.30am-9am'
			}
			else if(this.chosenDaypart === 4) {
				if(this.chosenYear <= 1982) return 'Mon-Fri 9am-1pm'
				return 'Mon-Fri 9am-12nn'
			}
			else if(this.chosenDaypart === 5) {
				if(this.chosenYear <= 1982) return 'Mon-Fri 1pm-4pm'
				return 'Mon-Fri 12nn-4pm'
			}
			else if(this.chosenDaypart === 6) {
				return 'Mon-Fri 4pm-7pm'
			}
			else if(this.chosenDaypart === 7) {
				return 'Mon-Fri 7pm-12mn'
			}
			return ''
		},
		markets() {
			return [
				{id: 110, name: 'Sydney'},
				{id: 111, name: 'Melbourne'},
				{id: 112, name: 'Brisbane'},
				{id: 113, name: 'Adelaide'},
				{id: 114, name: 'Perth'},
			]
		},
		filteredData() {
			let myd = []
			if(this.chosenMarket && this.chosenDaypart) {
				myd = this.returnedData.nmr.data.filter(item =>
					item.mktid === this.chosenMarket
					&& item.daypart_id === this.chosenDaypart
					&& item.year === this.chosenYear
				)
			}
			return myd
		},
		surveyList() {
			let survs = []
			let survnicenames = []
			for(let d of this.filteredData) {
				let mynicename =  "S" + d.survnum + " " + d.year
				if(!survnicenames.includes(mynicename)) {
					survs.push({
						id: d.survey_code,
						nicename: mynicename,
					})
					survnicenames.push(mynicename)
				}
			}
			return survs
		},
		stationList() {
			let stns = []
			let stids = []
			for(let d of this.filteredData) {
				for(let s of d.jsob) {
					if(!stids.includes(s.stid)) {
						stns.push({
							stid: s.stid,
							name: s.name,
						})
						stids.push(s.stid)
					}
				}
			}
			return stns
		},
		rows() {
			let rows = []
			for(let st of this.stationList) {
				let myrow = []
				myrow.stationName = st.name
				for(let surv of this.surveyList) {
					myrow[surv.id] = this.getCellData(st.stid, surv.id)
				}
				rows.push(myrow)
			}
			return rows
		},
		activeNotes() {
			let n = []
			for(let note of this.returnedData.nmr.notes) {
				if (note.years.includes(this.chosenYear) && (note.mktid === 0 || note.mktid === this.chosenMarket) && (note.daypart === 0 || note.daypart === this.chosenDaypart)) {
					n.push(note)
				}
			}
			return n
		},
	},
	methods: {
		initialFetch() {
			let self = this
			axios.get(
				this.store.apiUrl+"cra-wayback-data"
			).then(function (response) {
				self.returnedData = response.data
				if(!self.returnedData.nmr) self.wasLoadingError = true
			}).catch(function (error) {
				self.wasLoadingError = true
				console.log(error);
			});
		},
		getCellData(stid, survid) {
			let survitem = this.filteredData.find(
				item => item.survey_code === survid && item.daypart_id === this.chosenDaypart
			)
			if(survitem) {
				let valitem = survitem.jsob.find(item => item.stid === stid)
				if(valitem) return valitem.val
			}
			return null
		},
		getTooltip(val, stnname) {
			let out = ''
			if(val && val.length > 0 && stnname && stnname.length > 0) {
				let mydaypartob = this.dayparts.find(item => item.id === this.chosenDaypart)
				out = 'For 10+ ' + mydaypartob.name + ", "
				out += stnname
				out += ' had ' + val + '%' + ' share of listening'
			}
			return out
		},
	},
	watch: {
		chosenDaypart() {
			if(this.chosenDaypart !== 1 && this.chosenYear < 1975) this.chosenYear = 1975
		},
	},
	mounted() {
		document.title = 'CRA Ratings Wayback Machine'
		this.initialFetch()
	},
	created() {
		let queryString = window.location.search
		let urlParams = new URLSearchParams(queryString)
		if(urlParams.has('iframe')) {
			let isIframe = urlParams.get('iframe')
			if(isIframe === '1' || isIframe === 'true' || isIframe === true) {
				this.store.inIframe = true
			}
		}
	},
}
</script>
<style scoped>
#appportal {
	min-height: 100vh;
	background-color: #F9F9F9;
}
#appportal.iniframe {
	background-color: #FFF;
}
.header {
	height: 70px;
	background: transparent linear-gradient(270deg,  #E81F76 0%,  #00AEEF 100%) 0% 0% no-repeat padding-box;
	display: flex;
	align-items: center;
}
.header .logo-hold {
	height: 50px;
	display: flex;
	align-items: center;
}
.header .logo-hold img {
	height: 100%;
}
.inner {
	width: 1600px;
	max-width: 1600px;
	margin: 0 auto;
}
.inner.mainsection.iniframe {
	padding-top: 0;
}
.inner .inside {
	width: 1400px;
	max-width: 1400px;
	padding: 40px 0 10px;
	margin: 0 auto;
}
.inner .inside.table-holder,
.inner .inside.chart-holder {
	padding-top: 0;
}
.inner .inside.chart-holder {
	height: auto;
	padding-bottom: 0;
}
.togglers {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}
.table-chart-select-holder {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}
.selections-hold {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 20px 30px;
	background-color: var(--grey_main);
	border-bottom: 1px solid #CCC;
}
.selections-group {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.provider-logo {
	height: 60px;
	max-width: 200px;
	display: flex;
	align-items: center;
}
.provider-logo img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
.selector {
	margin-right: 20px;
}
.selector p {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 5px;
}
.bottom-section {
	margin-bottom: 50px;
}
.bottom-section.chartgap {
	margin-top: 50px;
}
.cfbtn {
	margin-right: 10px;
	font-size: 10px;
	padding: 5px 10px;
	background: var(--grey_main);
	border-radius: 5px;
	border: 1px solid #CCC;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
}
.cfbtn:hover {
	background-color: var(--grey_main_hover);
}
.notes {
	padding: 20px 20px;
}
.notes p {
	color: #888;
	font-style: italic;
}
#ratings-table .headrow td {
	padding-top: 20px;
}
#ratings-table .headrow.dprow td {
	padding-top: 20px;
	padding-bottom: 0;
}

@media screen and (max-width: 1720px) {
	.inner {
		width: 90%;
		max-width: 90%;
		padding: 0 1%;
	}
	.iniframe .inner {
		width: 98%;
		max-width: 98%;
	}
	.inner .inside {
		width: 100%;
		max-width: 100%;
	}
}

@media screen and (max-width: 800px) {
	.selections-hold {
		display: block;
	}
	.selections-group {
		flex-wrap: wrap;
	}
	.selector {
		margin-bottom: 10px;
		width: 30%;
		flex-shrink: 0;
		margin-right: 0;
		gap: 20px;
	}
	.provider-logo {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.selector {
		width: 100%;
	}
}
</style>